import React from "react"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import { ReactComponent as LogoSvg } from "../../images/logosvg.svg" /* const getImage = graphql`
  query herologo {
    herologo: file(relativePath: { eq: "logohero.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }    const { herologo } = useStaticQuery(getImage)

` */
/* import { graphql, useStaticQuery } from "gatsby"
 */
/* import Img from "gatsby-image"
 */ const Hero = ({ img, className, home, about, error }) => {
  return (
    <BackgroundImage
      className={className}
      fluid={img}
      home={home}
      about={about}
      error={error}
    >
      <Scanlines className="scanlines"></Scanlines>
      <LogoS className="neon" error={error} /> <Scan />
      <BlackBox error={error}>
        <h1>Error this page doesnt exist</h1>
        <p>Please return back home theres nothing to see here</p>
      </BlackBox>
    </BackgroundImage>
  )
}

export default styled(Hero)`
  min-height: ${props => (props.home ? "500px" : "500px")};
  min-height: ${props => (props.error ? "76vh" : "500px")};

  background-position: center;
  background-position: ${props => (props.about ? "top" : "center")};

  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`

/* const Text = styled.h1`
  color: white;
` */
const LogoS = styled(LogoSvg)`
  fill: white;
  height: 300px;
  z-index: 3;
  display: ${props => (props.error ? "none" : "")};

  filter: drop-shadow(0 0 1px #ffffff) drop-shadow(0 0 2px #ffffff)
    drop-shadow(0 0 3px #ffffff) drop-shadow(0 0 5px #00f)
    drop-shadow(0 0 7px #00f) drop-shadow(0 0 9px #00f);
`
const Scanlines = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0.2) 70%,
    rgba(0, 0, 0, 0.6)
  );
  box-shadow: inset 0 0 10em rgba(0, 0, 0, 0.75);
  background-size: 100% 0.3rem;
  position: absolute;
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  pointer-events: none;
`
const Scan = styled.div`
  z-index: 2;

  &,
  &::before,
  &::after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 5%;
    background-color: hsla(0, 0%, 0%, 0.13);
    box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.25);
    animation: scan 16s linear infinite;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    height: 30%;
  }

  &::after {
    top: -100%;
  }

  @keyframes scan {
    0% {
      top: 0%;
    }
    100% {
      top: 90%;
    }
  }
`
const BlackBox = styled.div`
  background-color: black;
  color: white;
  text-transform: uppercase;
  display: ${props => (props.error ? "block" : "none")};

  h1 {
    font-size: 4rem;
    font-family: "Share Tech", sans-serif;
  }
  p {
    font-size: 2rem;
  }
  text-align: center;
  padding: 0.5rem;
  text-justify: center;
`
