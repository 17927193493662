import React, { useState } from "react"
import styled from "styled-components"
import NavbarHead from "./NavbarHead"
import ListBox from "./ListBox"
const Navbar = () => {
  const [isOpen, setNav] = useState(false)

  return (
    <NavContainer>
      <NavbarHead isOpen={isOpen} setNav={setNav} />
      <ListBox isOpen={isOpen} />
    </NavContainer>
  )
}

export default Navbar

const NavContainer = styled.nav`
  margin: 0 auto;
  max-width: 1170px;

  @media (min-width: 815px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
