export default [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/projects",
    text: "projects",
  },

  {
    path: "/art",
    text: "art",
  },
  {
    path: "/about",
    text: "About",
  },
  {
    path: "/contact",
    text: "contact",
  },
]
