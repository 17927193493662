import React from "react"
import links from "../constants/links"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"
import { FaChevronCircleUp } from "react-icons/fa"
const Footer = () => {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <FooterWrapper>
      <div>
        {links.map((item, index) => {
          return (
            <AniLink
              cover
              direction="up"
              duration={1}
              bg="black"
              key={index}
              to={item.path}
            >
              {item.text}
            </AniLink>
          )
        })}
      </div>
      <FaChevronCircleUp className="arrowUp" onClick={() => scrollToTop()} />
    </FooterWrapper>
  )
}

export default Footer
const FooterWrapper = styled.footer`
  text-align: center;
  padding-top: 1rem;
  width: 100%;
  background-color: black;
  color: white;
  scroll-behavior: smooth;

  a {
    color: white;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0.5rem 1rem;
    font-size: 1.5rem;
    font-weight: 700px;
    &:hover {
      color: #00b1d2;
    }
  }
  .arrowUp {
    font-size: 2rem;
    /*     margin: 1rem auto;
 */
    margin-top: 1rem;
    &:hover {
      color: #00b1d2;
    }
  }
`
