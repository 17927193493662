import React from "react"
import links from "../constants/links"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Buttons from "./Buttons"
const ListBox = props => {
  return (
    <ListBoxWrapper open={props.isOpen} className="Nav1">
      {links.map((item, index) => {
        return (
          <li key={index}>
            <AniLink fade to={item.path} className="nav-link">
              <Buttons numberIndex={index}>{item.text}</Buttons>
            </AniLink>
          </li>
        )
      })}
    </ListBoxWrapper>
  )
}

const ListBoxWrapper = styled.ul`
  color: white;

  li {
    border: none;
    list-style-type: none;
    height: 26px;
    display: flex;
    align-items: center;
  }

  .nav-link {
    text-decoration: none;
    text-transform: capitalize;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      height: 100%;
      width: 100%;
    }
  }
  @media (max-width: 815px) {
    display: none;
    display: ${props => (props.open ? "flex" : "none")};
    transition: flex 0.6s linear;

    flex-direction: column;
    align-items: center;
    height: 200px;
    width: 100%;
    margin: 0 auto;

    li {
      margin: 5px auto;
      justify-self: center;
      width: 100%;
    }
    .nav-link {
      width: 100%;
    }
    div {
      width: 100%;
      text-align: center;
    }
  }
  @media (min-width: 815px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-image: none !important;
    padding: 0 1em;
    height: auto;
    width: auto;
    margin: 0;
    li {
      margin: 0;
      border: none;
      color: white;
    }
  }
  @media (min-width: 1000px) {
    li {
      margin: auto 10px;
    }
  }
`

export default ListBox
