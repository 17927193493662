import React from "react"
import styled from "styled-components"
import { ReactComponent as Logosvg } from "../../images/logosvghorizontal.svg"

import { GiBowenKnot } from "react-icons/gi"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const NavbarHead = props => {
  const toggleNav = () => {
    props.setNav(isOpen => !isOpen)
  }
  return (
    <HeaderWrapper>
      <AniLink fade to="/" aria-label="Logo to Home">
        <Logo alt="company name"></Logo>
      </AniLink>
      <MenuIcon onClick={toggleNav} />
    </HeaderWrapper>
  )
}

export default NavbarHead
const HeaderWrapper = styled.div`
  padding: 0.1rem 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const MenuIcon = styled(GiBowenKnot)`
  font-size: 1.6rem;
  color: white;
  cursor: pointer;
  @media (min-width: 815px) {
    display: none;
  }
`
const Logo = styled(Logosvg)`
  height: 60px;
  fill: white;
  &:hover {
    fill: #00b1d2;
  }
`
