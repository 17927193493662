import React from "react"
import styled from "styled-components"
const Buttons = ({ children }) => {
  return <Button>{children}</Button>
}

export default Buttons
const Button = styled.div`
  text-transform: uppercase;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  font-size: 1.3rem;
  color: white;
  margin: 0 0.5rem;
  font-family: "Poiret One", cursive;
  font-weight: 700;

  &:hover {
    color: #00b1d2;
  }
`
